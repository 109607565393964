import styled from "styled-components";

export const MainHomeContainer = styled.div`
.desktopView{    place-content: space-between;
display: inline-flex;
    width: 20rem;
    
    padding: 5% 2% 2% 2%;
    flex-flow: wrap;
    .ImageSize{
    width: 150px;
    height: 125px;
    position: relative;
    margin: 10px 0px 0px 50px !important;
}}
    .NewsCard{
width:30rem;
    text-align: -webkit-auto;
    margin-bottom: 20px;
   @media only screen and (max-width: 600px) {width:22rem;}
}
   
    .CardDiv{
    text-align: -webkit-center;
    margin: 15px;
    .ImageSize{
    width: 150px;
    height: 125px;
    position: relative;
    margin: 10px 0px 0px 50px !important;
}
    }
    
    .BtnGrp{
font-family: monospace;    }
.NewsFeed{
display: flex;
    justify-content: center;
flex-flow: wrap;
}
`;