import Category from './Components/Category';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styled from "styled-components";

export const AppContainer = styled.div`
 background-image:url('../softechweb.jpg');
  background-size: cover;
  background-repeat: no-repeat;
`;

function App() {
  return (
    <AppContainer>
      <Nav className="justify-content-center">
    <Nav.Item>
    
<Navbar.Brand href="https://govindmukund.com" style={{fontWeight:'800',fontSize:'xx-large'}}><span style={{color:'red'}}>G</span><span style={{color:'blue'}}>OVIND</span><span style={{color:'red'}}>M</span><span style={{color:'blue'}}>UKUND</span></Navbar.Brand>
    </Nav.Item>
    
  </Nav>
      <Category />
    </AppContainer>
  );
}

export default App;
